/* BasicServicesTable.css */

.table-container {
  background-color: #ffffff;
  padding: 1rem;
}

.table-wrapper {
  max-width: 1024px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
  overflow-x: hidden; /* No scrolling by default */
  overflow-y: hidden;
}

.table-wrapper h1 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  padding: 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border: none;
}

.table th, .table td {
  padding: 0.75rem;
  border: 1px solid #ddd;
  text-align: left;
}

.table th {
  background-color: #f1f5f9;
  font-weight: 600;
}

.table td {
  background-color: #fff;
}

.table-row:hover {
  background-color: #f9fafb;
}

/* Media Query for Mobile View */
@media (max-width: 770px) {
  .table-wrapper {
    overflow-x: auto; /* Enable horizontal scrolling on mobile */
  }

  .table {
    min-width: 800px; /* Prevent table from collapsing */
    display: block; /* Allow table to scroll */
  }
}
