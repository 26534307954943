.politicalnewscontainer {
  width: 100%; /* Parent container full width */
  max-width: 800px; /* Maximum width to avoid extreme stretching */
  margin: 0 auto;
}

.shadowbox {
  width: 100%; /* Ensures the box takes up full width of its container */
  padding: 16px; /* Add some padding for spacing */
  margin-top: 16px; /* Adds vertical margin */
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the left */
  border-radius: 8px; /* Slightly larger rounding for consistency */
  box-sizing: border-box; /* Ensures padding/borders don't affect width */
  background-color: #fff; /* Ensure background color is applied */
}


.companyName {
  font-size: 12px;
  color: #0e0e0e;
  margin-bottom: 16px;
}
.newsHeading {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 6px;
  /* font-size: 14px;
  color: #0e0e0e;
  cursor: pointer;
  margin-bottom: 16px; */
}
.rounded {
  border-radius: 5px !important;
  max-height: 120px !important;
  min-height: 120px;
  max-width: 210px;
  min-width: 210px;
}
@media (max-width: 600px) {
  .rounded {
    display: none;
  }
}
.paragrey {
  color: #707070;
  font-style: normal;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  margin-top: 0px;
}
.calender-box {
  width: 45px;
  height: 45px;
  font-size: 15px;
  text-align: center;
  line-height: 1.5px;
  background-color: #e5f2ff !important;
  color: #0084ff !important;
  padding: 0.25px;
  border-radius: 50px;
}
.paradark {
  font-size: 14px;
  cursor: pointer;
  color: #374948 !important;
  margin-bottom: 0px;
}
.hoverable:hover {
  cursor: pointer;
}
