.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
  background: #f0f0f0; 
  padding: 2px 0;
  margin-bottom: 10px;
  margin-top: 50px;
  margin-right:55px;
  margin-left:55px;
}

.marquee {
  display: inline-block;
  animation: marquee 220s linear infinite;
}

.marquee-item {
  display: inline-block;
  padding: 0 2px;
  font-size: 14px;
  font-weight: bold;
  color: #333; 
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.marquee-container:hover .marquee {
  animation-play-state: paused;
}
