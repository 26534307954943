/* .tab-content{

} */
.fade {
  transition: opacity 0.15s linear;
}
.tablebrokerage {
  width: 100%;
  padding: 5px;
}
.tablebrokerage tr td {
  border-top: 0px solid #dee2e6;
  padding: 3px 2px;
}
.textleft {
  text-align: left;
}
.roboto {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.textright {
  text-align: right;
}
.tab-content {
  transition: opacity 0.15s linear;
}
