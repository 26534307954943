.icon {
  width: 18px;
  height: 18px;
  border-radius: 20px;
  margin-bottom: 10px;
  color: #777d74;
  vertical-align: middle;
}
.footerh3 {
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 15px;

  text-transform: capitalize;
  font-weight: 400;
  color: #131722;
  letter-spacing: 0.3px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.footerp {
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 10px;
  letter-spacing: 0.3px;
  color: #777d74;
  line-height: 1.8;
  height: 70px;
  text-align: center;
  justify-content: center;
  display: flex;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
}
.rowfoot {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.container {
  width: 100%;
  position: relative;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  color: #777d74;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.menu-row {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 12px;
}
.footer-title {
  font-size: 17px !important;
  font-weight: bold;
  color: #3c4043 !important;
}
.hover-para p {
  color: #777d74;
  font-size: 14px;
}
.hover-para p svg {
  color: #777d74;
  position: relative;
  top: 4px;
}
ul .hover-para p:hover {
  color: #34a853 !important;

  cursor: pointer;
}

.footer-content-heading {
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.footer-content {
  padding-left: 15px;

  font-weight: 400;
  font-style: normal;
  color: #777d74;
  font-size: 14px;
  line-height: 1.7;
}
.footer-content p {
  font-size: 14px;
  text-align: justify;
  margin-top: 0px;
  margin-bottom: 16px;
}
/* .para {
  color: #777d74;
  font-size: 14px;
} */

.para {
  color: #777d74;
  font-size: 14px;
  margin-top: 10px; /* Default margin for desktop */
  font-size: 14px; /* Adjust the font size if needed */
}

/* For mobile view (adjust the breakpoint as needed) */
@media (max-width: 768px) {
  .para {
    margin-top: 50px; /* Add extra margin on mobile to avoid overlap */
    margin-bottom: 0px; /* Ensure space from the bottom */
    line-height: 1.5; /* Adjust line height for better readability */
  }

  .rowfoot .last ul li {
    margin-bottom: 5px; /* Adds spacing between the list items */
  }
}

.para svg {
  color: #777d74;
  position: relative;
  top: 4px;
}
.rowfoot li {
  text-decoration: none;
  list-style: none;
  font-size: 15px;
  color: #777d74;
  margin-left: 10px;
}
.icn ul {
  padding-left: 0;
}

@media (max-width: 768px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
    margin: auto;
    justify-content: center;
    display: grid;
    width: 100%;
  }

  .footer-content p {
    font-size: 12px;
  }

  .col {
    padding-right: 5px;
    padding-left: 5px;
  }

  .rowfoot li {
    font-size: 13px;
  }

  .para {
    font-size: 12px;
  }

  .footer-title {
    font-size: 14px !important;
  }

  .hover-para p {
    font-size: 12px;
  }
  .footerh3 {
    font-size: 10px; /* Adjust font size for smaller screens */
    margin-bottom: 10px; /* Adjust margin for smaller screens */
  }
  .row {
    position: relative;
    bottom: -25px;
  }
}

@media (max-width: 480px) {
  .footerh3 {
    font-size: 10px; /* Further adjust font size for smaller screens */
    margin-bottom: 8px; /* Further adjust margin for smaller screens */
  }
}
