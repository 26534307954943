@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollable-container {
  overflow-y: hidden;
  transition: overflow-y 0.3s;
}

.scrollable-container:hover {
  overflow-y: auto;
}

/* Optional: Hide the scrollbar track and thumb */
.scrollable-container::-webkit-scrollbar {
  width: 6px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.scrollable-container::-webkit-scrollbar-track {
  background-color: transparent;
}
body {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 1.8;
  color: #777d74 !important;
  text-align: left;
}

p {
  font-weight: 500;
  font-style: normal;
  font-size: 19px;
  line-height: 1.8;

  margin-top: 0px;
  margin-bottom: 0px;
}
h5 {
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0px;
  color: #131722;
  letter-spacing: 0.3px;
}
h2 {
  font-size: 22px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0px;
  line-height: 1.5;
  color: #131722;
  letter-spacing: 0.3px;
}
h6 {
  font-family: "poppins", sans-serif !important;
  font-weight: 600;
  color: #374948;
  margin: 0px;
  line-height: 1.5;
}
h4 {
  font-family: -apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu,
    sans-serif !important;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0px;
  line-height: 1.5;
  color: #131722;
  letter-spacing: 0.3px;
}
a {
  color: #34a853;
  text-decoration: none;
  background-color: transparent;
}
/* styles.css */
/* input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
/* .scrollable-container {
  overflow-y: hidden;
  transition: overflow-y 0.3s;
}

.scrollable-container:hover {
  overflow-y: auto;
} */

/* Optional: Hide the scrollbar track and thumb */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 20px;
}
