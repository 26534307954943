/* Disclaimer.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #ffffff;
}

.section {
  background-color: #e2e8f0;
  width: 102%;
  padding: 32px;
  display: flex;
  flex-direction: column; /* Stacked by default for mobile */
  justify-content: center;
  margin-top: 20px;
}

.textSection {
  flex-basis: 80%; /* Full width on mobile */
  margin-bottom: 16px;
}

.imageSection {
  flex-basis: 100%; /* Full width on mobile */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.imageStyle {
  width: 25%; /* Adjust image size */
}

.disclaimerText {
  font-size: 16px;
  color: #4a5568;
  margin-bottom: 16px;
}

.whiteSection {
  background-color: #ffffff;
  width: 80%;
  padding: 32px;
}

/* Media Queries for larger screens (desktop) */
@media (min-width: 680px) {
  .section {
    flex-direction: row; /* Side-by-side on desktop */
  }

  .textSection {
    flex-basis: 63.60%; /* 2/3 width on desktop */
  }

  .imageSection {
    flex-basis: 33.33%; /* 1/3 width on desktop */
  }
}
