.column {
  flex: 0 0 50%;
  max-width: 50%;
  border-bottom: 1px solid #c6c5ca;
}
.Broker-container {
  display: flex;
  text-align: center;
  flex: 0 0 100%;
  max-width: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.Broker-nav {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.Broker-nav-li {
  margin-bottom: 5px;
}

.column-full {
  flex: 0 0 100%;
  max-width: 100%;
}
.column-md {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.margincalculator {
  display: flex;
}
.searchicon {
  display: flex;
  min-width: 250px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50rem;
  justify-content: center;
  align-items: center;
  border: 1px solid #dee2e6 !important;
}
.BoxContent {
  display: flex;
  width: 250px;

  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid #3e404288 !important;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  position: relative;
  top: 50px;
}
.tablecalaculator thead th {
  font-weight: 400;
  color: #707070;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.tablecalaculator th {
  padding: 8px;
}
.tables tbody td {
  font-weight: 500;
  color: #3c4043;
  font-size: 13px;
}
.tables td {
  padding: 0.5rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}
.boxtable td {
  padding: 0.5rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.formcontroll {
  height: 30px;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #a7a4a4;
  font-size: 13px;
  color: #777d74;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  outline: medium none !important;
  width: 100%;
  display: block;
}
.tables {
  width: 100%;
  margin-bottom: 15px;
  color: #212529;
}
.calc {
  width: 100%;
  display: flex;
  justify-content: center;
}
.brokerage-calc {
  /* margin-top: 15px;
  width: 100%;
  margin-bottom: 16px;
  padding: 20px 25px; */
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.15) !important;
}
.Broker-Small-container {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.Broker-Small-container-right {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;

  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.form-group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.labels {
  color: #374944;
  margin-left: 15px;
  font-weight: normal;
  display: inline-block;
  margin-bottom: 5px;
}
.form-group Button {
  position: relative;
  left: 20px;
}
@media (max-width: 576px) {
  .Broker-Small-container p b {
    display: flex;
    width: 200px !important;
    height: 30px;
    margin-bottom: 30px;
  }
  .BoxContent {
    width: 180px;
  }
  .column {
    flex: 0 0 100%;
    max-width: 100%;
    border-bottom: none;
    position: relative;
    top: 20px;
  }
  .searchicon Button {
    width: 250px;
  }
  .margincalculator {
    display: flex;
    flex-direction: column;
  }
  .table-responsive {
    width: 320px;
  }
  .tablecalaculator thead {
    overflow-x: auto;
  }
  .Broker-container {
    display: flex;

    flex-direction: column;
  }
  .Broker-Small-container-right {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
@media (max-width: 992px) {
  .Broker-Small-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .column {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .Broker-Small-container {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px;
  }
  .Broker-Small-container-right {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 10px;
    padding-left: 10px;
  }
}
