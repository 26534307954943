.table-stack {
  border-radius: 20px;
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
  color: #212529;
}
.table-stack thead th {
  font-weight: 400;
  color: #707070;
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-stack tr:nth-child(odd) td {
  background-color: #ebf2f8;
}

.responsive-label {
  display: none;
}
.start {
  text-align: start;
}
.center {
  text-align: center;
  width: fit-content;
}
.end {
  text-align: end;
}
@media (max-width: 500px) {
  .table-stack thead {
    display: none;
  }

  .table-stack td {
    display: block;

    padding-left: 50%;
  }
  .table-stack tr {
    border: 1px solid #dee2e6;
  }
  .table-stack td:nth-child(odd) {
    background-color: #ebf2f8;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .table-stack td .responsive-label {
    display: inline;
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
  }
  .start {
    text-align: start;
  }
  .center {
    text-align: start;
  }
  .end {
    text-align: start;
  }
}
.custom-select {
  border: 1px solid #c6c5ca;
  padding: 8px 5px;
  border-radius: 3px;
  color: #34a853;
  outline: none;
}

.custom-select:focus {
  border-color: #34a853; /* Change the border color when focused */
  outline: none; /* Remove the default focus outline */
}
