.red-text {
  color: #dc3545;
}

.green-text {
  color: #34a853;
}
.redbg-text {
  color: #dc3545 !important;
  background-color: #ffdfe2;
}

.greenbg-text {
  color: #34a853 !important;
  background-color: #d1ffdd;
}
