.content-page {
  overflow: hidden;
  padding: 100px 90px 30px;
  height: auto;
  transition: all 0.3s ease-out 0s;
  width: 100%;
}
.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}
.box {
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center {
  margin: 0;

  top: 50%;
  left: 50%;
  width: 100%;
  translate: translate(-50%, -50%);
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  top: 40px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.phoneInput {
  border: 1px solid #e0e0e0;
  background: #fefefe;
  border-radius: 6px;
  padding: 8px;
  text-align-last: start;
}
.h4 {
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0px;
  line-height: 1.5;
  color: #131722;
  letter-spacing: 0.3px;
}
h6 {
  font-weight: 600;
  margin: 0px;
  line-height: 1.5;
  color: #374948;
  font-size: 15px;
}
.fcs:focus {
  border: none;
  outline: none;
}
@media (max-width: 800px) {
  .content-page {
    width: fit-content;
    overflow: auto;
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .card {
    min-width: 100%;
  }
}
